import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Header from "../../components/header.js"
import Footer from "../../components/footer.js"
import panel from '../../components/panel.module.css'
import styles from './css/varenlay.module.css'
import {Helmet} from 'react-helmet'

const VLPage = ( {data} ) => (
  <div>  
    <Header />
    <div className={panel.panel}>
      <div className={panel.centeredText}>
        <h1 className={panel.h}>Varen Lay's Guaranteed-Delivery Transport Agency</h1>
        <h3 className={panel.h}>A Step-by-Step Guide to Attaining Fulfillment After the End of the Universe</h3>
        <h3 className={panel.h}>A Postmodern Epic for a Postmodern Audience</h3>
      </div>
        <Helmet>
            <title>Varen Lay's Guaranteed-Delivery Transport Agency</title>
        </Helmet>
      <p>Having conquered the world already, the bored Tyrant Queen of Amn departs her castle in search of challenges to bring her new purpose.</p>
      <p>Meanwhile, a failed civil servant joins a game to decide the fate of a universe that doesn't yet exist.</p> 
      <p>This is the story of their journeys through the Alluve -- the impossible wasteland that awaits after the end of all time and space.</p>
      <p>More on the second and fourth Monday of each month.</p>
      <div className={styles.borderLine}></div>
      <div className={panel.centeredText}>
        <h2 className={panel.h}>Catalogue</h2>
      </div>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div><Link to={node.frontmatter.path}>{node.frontmatter.date} | {node.frontmatter.title}</Link></div>
            ))}
    </div>
    <Footer />
  </div>
)

export default VLPage

export const query = graphql`
  query {
   allMarkdownRemark(
    sort: {fields: [frontmatter___date], order:DESC},
    filter: {fileAbsolutePath: {regex: "/content/varenlay/.*$/"}}
  ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          frontmatter{
            date(formatString: "YYYY-MM-DD")
            title
            path
          }
        }
      }
    }
  }
`
